import React from 'react'
import Banner from '../Banner/Banner'
import Products from '../Products/Products'
import video from '../assets/images/salt-video.mp4'
import './home.scss'
import Services from '../Services/Services'
import Slider from '../Slider/Slider'

const Home = () => {
  return (
    <div>
      <Slider />
     
      <Products />
      <div className="video-background">
        <video autoPlay loop muted>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* Add other content on top of the video background if needed */}
        <div className="content">
          <h1> <span>Qalam</span> में ताकत है </h1>

        </div>
      </div>
      <Banner />
      <Services />
      <div className="tools-feature-wrapper d-none d-sm-block">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-lg-5 col-sm-5">
              <div className="tools-feature right" data-aos="fade-up-right">
                <strong>Electrolyte Balance</strong>
                <p>
                  Salt, or sodium chloride, is a key electrolyte that helps maintain the balance of fluids in and out of cells.
                </p>
              </div>
              <div className="tools-feature right" data-aos="fade-up-right">
                <strong>Nerve Function</strong>
                <p>
             It helps transmit signals between nerve cells and muscles, facilitating proper muscle contractions and coordination.
                </p>
              </div>
              <div className="tools-feature right" data-aos="fade-up-right">
                <strong>Blood Pressure Regulation</strong>
                <p>
                  It helps regulate blood pressure. Maintaining the right balance of these electrolytes is crucial for a healthy cardiovascular system.
                </p>
              </div>
              <div className="tools-feature right" data-aos="fade-up-right">
                <strong>Fluid Balance</strong>
                <p>
                  It regulate the balance of fluids in the body, preventing dehydration or overhydration. This is especially important during physical activity or in hot weather when the body loses fluids through sweat.
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2 d-none d-sm-block">
              <div className="mobile-img-wrapper" data-aos="zoom-in-up" data-aos-duration={1000} />
            </div>
            <div className="col-md-5 col-lg-5 col-sm-5">
              <div className="tools-feature" data-aos="fade-up-left">
                <strong>Nutrient Absorption</strong>
                <p>
                  Salt is necessary for the absorption of certain nutrients in the small intestine. It aids in the absorption of glucose, amino acids, and other important substances.
                </p>
              </div>
              <div className="tools-feature" data-aos="fade-up-left">
                <strong>pH Regulation</strong>
                <p>
                  Salt plays a role in maintaining the body's acid-base balance, helping to regulate the pH levels in various tissues and fluids.
                </p>
              </div>
              <div className="tools-feature" data-aos="fade-up-left">
                <strong>Adrenal Function</strong>
                <p>
                  The adrenal glands, which produce hormones that regulate stress response and other functions, rely on sodium for proper functioning.
                </p>
              </div>
              <div className="tools-feature" data-aos="fade-up-left">
                <strong>Muscle Contraction</strong>
                <p>Sodium ions are involved in the process of muscle contraction. Adequate salt levels help ensure proper muscle function and prevent muscle cramps.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Home