import React from 'react'
import './Services.scss'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import item1 from '../assets/images/boxes/1.png'
import item2 from '../assets/images/boxes/2.png';
import item3 from '../assets/images/boxes/3.png';
import item4 from '../assets/images/boxes/4.png';
import item5 from '../assets/images/boxes/5.png';
import item6 from '../assets/images/boxes/6.png';
import item7 from '../assets/images/boxes/7.png';
import item8 from '../assets/images/boxes/8.png';
import item9 from '../assets/images/boxes/9.png';
import item10 from '../assets/images/boxes/10.png';
import item11 from '../assets/images/boxes/11.png';
import item12 from '../assets/images/boxes/12.png';
import item13 from '../assets/images/boxes/13.png';
import item14 from '../assets/images/boxes/14.png';
// import item15 from '../assets/images/products/BLACK_SALT_1.png';
import item16 from '../assets/images/products/blacksalt.png';
import item17 from '../assets/images/products/chaat-masala.png';
// import item18 from '../assets/images/products/COBRA1.png';
import item19 from '../assets/images/products/COOKING_SALT_2.png';
// import item20 from '../assets/images/products/DIAMOND_BLACK_SALT.png';
import item21 from '../assets/images/products/iodisalt.png';
import item22 from '../assets/images/products/iodised.png';
import item23 from '../assets/images/products/Jsns_Rock_salt.png';
// import item24 from '../assets/images/products/medicare.png';
import item25 from '../assets/images/products/mitha_soada.png';
import item26 from '../assets/images/products/pink-salt.png';
import item27 from '../assets/images/products/S2.png';
import item28 from '../assets/images/products/S3.png';
import item29 from '../assets/images/products/S4.png';
import item30 from '../assets/images/products/S5.png';
import item31 from '../assets/images/products/S6.png';
import item32 from '../assets/images/products/sargidalia.png';
// import item33 from '../assets/images/products/tota-salt.png';
// import item34 from '../assets/images/products/tota-salt2.png';


const Services = ({ props }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div className='product-wrapper'>
      <h1>Our Products </h1>
        <Carousel
          showArrows={false}
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          //   ssr={true} // means to render carousel on server-side.
          infinite={true}
        autoPlay={true}
          autoPlaySpeed={1500}
          keyBoardControl={false}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className='slider-img'><img src={item1} alt='salt' /></div>
          <div className='slider-img'><img src={item2} alt='salt' /></div>
          <div className='slider-img'><img src={item3} alt='salt' /></div>
          <div className='slider-img'><img src={item4} alt='salt' /></div>
          <div className='slider-img'><img src={item5} alt='salt' /></div>
          <div className='slider-img'><img src={item6} alt='salt' /></div>
          <div className='slider-img'><img src={item7} alt='salt' /></div>
          <div className='slider-img'><img src={item8} alt='salt' /></div>
          <div className='slider-img'><img src={item9} alt='salt' /></div>
          <div className='slider-img'><img src={item10} alt='salt' /></div>
          <div className='slider-img'><img src={item11} alt='salt' /></div>
          <div className='slider-img'><img src={item12} alt='salt' /></div>
          <div className='slider-img'><img src={item13} alt='salt' /></div>
          <div className='slider-img'><img src={item14} alt='salt' /></div>


        <div className='slider-img'><img src={item31} alt='salt' /></div>
        <div className='slider-img'><img src={item32} alt='salt' /></div>
        {/* <div className='slider-img'><img src={item33} alt='salt' /></div>
        <div className='slider-img'><img src={item34} alt='salt' /></div> */}
        {/* <div className='slider-img'><img src={item15} alt='salt' /></div> */}
        <div className='slider-img'><img src={item16} alt='salt' /></div>
        <div className='slider-img'><img src={item17} alt='salt' /></div>
        {/* <div className='slider-img'><img src={item18} alt='salt' /></div> */}
        <div className='slider-img'><img src={item19} alt='salt' /></div>
        {/* <div className='slider-img'><img src={item20} alt='salt' /></div> */}
        <div className='slider-img'><img src={item21} alt='salt' /></div>
        <div className='slider-img'><img src={item22} alt='salt' /></div>
        <div className='slider-img'><img src={item23} alt='salt' /></div>
        {/* <div className='slider-img'><img src={item24} alt='salt' /></div> */}
        <div className='slider-img'><img src={item25} alt='salt' /></div>
        <div className='slider-img'><img src={item26} alt='salt' /></div>
        <div className='slider-img'><img src={item27} alt='salt' /></div>
        <div className='slider-img'><img src={item28} alt='salt' /></div>
        <div className='slider-img'><img src={item29} alt='salt' /></div>
        <div className='slider-img'><img src={item30} alt='salt' /></div>
          
        </Carousel>
    </div>
  )
}

export default Services