import React from 'react'
import './Products.scss'
import pinkSalt from '../assets/images/products/1_1.png'
import blackSalt from '../assets/images/products/3_3.png'
import masala from '../assets/images/products/4_4.png'
import iodisedSalt from '../assets/images/products/5_5.png'
import det from '../assets/images/products/6_6.png'
import meethaSoda from '../assets/images/products/7_7.png'
const Products = () => {
  return (
    <div>
      <div className="service-new">
        <div className="container">
          <div className="service-bg">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6  service-img" data-aos="flip-left" data-aos-duration={1500}>
                <img src={pinkSalt} className="left" />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 service-text-wrapper" data-aos="zoom-in-up" data-aos-duration={1500}>
                <div className="service-text">
                  <strong className="grediant-text">Qalam 1kg Himalayan Pink Salt Powder
                  </strong>
                  <p>
                    Pink salt, also known as Himalayan salt, is a distinctive mineral salt renowned for its rosy hue derived from trace minerals. Mined in the Himalayan mountains, it is believed to offer potential health benefits and is popular in both culinary and wellness circles for its unique color, flavor, and alleged holistic properties.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-bg">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 service-text-wrapper" data-aos="zoom-in-up" data-aos-duration={1500}>
                <div className="service-text">
                  <strong className="grediant-text">Qalam 200gm Black Salt Powder</strong>
                  <p>
                    Black salt, also known as kala namak, offers health benefits. Rich in minerals, it aids digestion, reduces bloating, and may alleviate constipation. Its sulfur content contributes to skin health, combating acne and promoting a youthful appearance. Additionally, it adds a distinct flavor to dishes, enhancing culinary experiences.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 service-img" data-aos="flip-left" data-aos-duration={1500}>
                <img src={blackSalt} alt="" className="right" />
              </div>
            </div>
          </div>
          <div className="service-bg">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 service-img" data-aos="flip-left" data-aos-duration={1500}>
                <img className="left" src={masala} alt="" />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 service-text-wrapper" data-aos="zoom-in-up" data-aos-duration={1500}>
                <div className="service-text">
                  <strong className="grediant-text">Qalam Cooking Salt
                  </strong>
                  <p>
                    Cooking salt, essential in culinary alchemy, not just for seasoning but for chemistry. It amplifies flavors, balances sweetness, and draws out moisture. From delicate flakes of sea salt to coarse kosher crystals, its texture and purity transform dishes. In the kitchen, it's not just a condiment but a cornerstone.
                  </p>
                </div>
              </div>
            </div>
          </div>

     
          <div className="service-bg">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 service-text-wrapper" data-aos="zoom-in-up" data-aos-duration={1500}>
                <div className="service-text">
                  <strong className="grediant-text">Qalam Iodized salt</strong>
                  <p>
                    Iodized salt is table salt enriched with iodine, a vital micronutrient essential for thyroid function. This supplementation helps prevent iodine deficiency, supporting cognitive development and maintaining a healthy metabolism. Regular use of iodized salt is a simple and effective way to ensure individuals meet their daily iodine requirements.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 service-img" data-aos="flip-left" data-aos-duration={1500}>
                <img src={iodisedSalt} alt="" className="right" />
              </div>
            </div>
          </div>
          <div className="service-bg">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 service-img" data-aos="flip-left" data-aos-duration={1500}>
                <img className="left" src={det} alt="" />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 service-text-wrapper" data-aos="zoom-in-up" data-aos-duration={1500}>
                <div className="service-text">
                  <strong className="grediant-text">Qalam detergent 
                  </strong>
                  <p>
                  Qalam detergent   is a specially formulated cleaning agent designed to remove stains, dirt, and odors from clothing. It contains surfactants and enzymes that break down grime, ensuring effective cleaning. With various formulations available, these detergents cater to different fabrics and washing machine types, providing optimal laundry results.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="service-bg">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 service-text-wrapper" data-aos="zoom-in-up" data-aos-duration={1500}>
                <div className="service-text">
                  <strong className="grediant-text">Qalam Meetha Sodha</strong>
                  <p>
                    Baking soda, or sodium bicarbonate, is a versatile household ingredient known for its myriad benefits. It acts as a leavening agent in baking, neutralizes acids, and serves as a gentle abrasive for cleaning. Its versatility extends to personal care, where it can be used for oral hygiene, skincare, and deodorizing.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 service-img" data-aos="flip-left" data-aos-duration={1500}>
                <img src={meethaSoda} alt="" className="right" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Products