import React from 'react'
import './Footer.scss'
import map from '../assets/images/contact-icons/location.svg';
import email from '../assets/images/contact-icons/email.svg';
import phone from '../assets/images/contact-icons/phone.svg';
import { FaFacebook, FaYoutube } from "react-icons/fa";

const Footer = () => {
    return (
        <div>
            <div class="footer-outer">
                <div className="container">
                    <div className="footer-wrapper">
                        <div className="section">
                            <h2>About Us</h2>
                            <p>
                                Our product range reflects not just the diversity of Indian spices but also our commitment to excellence. We prioritize authenticity, ensuring that each product captures the true essence of the spices it represents.
                            </p>
                        </div>
                        <div className="section">
                            <h2>Follow Us</h2>
                            <ul className='socials'>
                                <li>
                                    <a id="sparkwork-social-facebook" target="_blank" href="https://www.facebook.com/qalamsaltofficial/"> <FaFacebook />
                                        Facebook</a>
                                </li>

                                <li>
                                    <a id="sparkwork-youtube-facebook" target="_blank" href="https://www.youtube.com/channel/UCuScenzNJTcKVVgCWkybRtg"><FaYoutube />Youtube</a>
                                </li>
                            </ul>
                        </div>
                        <div className="section">
                            <h2>Our Office</h2>
                            <ul>
                                <li>
                                    <img src={email} alt='' />

                                    arungumber663@gmail.com
                                </li>
                                <li>
                                    <img src={phone} alt='' />
                                    +91-8048777749
                                </li>
                                <li>
                                    <img src={map} alt='' />

                                    Kacha Bhagsar Road, Opp Khetarpal Mandir,  Muktsar, Punjab, India - 152026
                                </li>
                            </ul>
                        </div>
                        <div className="section">
                            <h2>Our Location</h2>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3438.8057195789156!2d74.50435757606763!3d30.46994049828473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39176fc37a378bf9%3A0xf952c45bde9fa40c!2sQalam%20salt!5e0!3m2!1sen!2sin!4v1708754668791!5m2!1sen!2sin" width="100%" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div className="copyright-wrapper">
                        <div className="terms">
                           <b>GSTNo.</b> 03ETMPS3542E1ZN
                        </div>
                        <div className="copy">© 2024 All Rights Reserved. Jeet Singh Nihal Singh.</div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Footer