import React from 'react'
import './About.scss'
import display from '../assets/images/about/laptop-banner.png'
import about1 from '../assets/images/about/about-01.png'
import about2 from '../assets/images/about/about-02.png'
import about3 from '../assets/images/about/about-03.png'
import about from '../assets/images/about/about-img.jpg'
import { FaCheck } from "react-icons/fa";
import { handleCallClick } from '../reusable/CallUs'
const About = () => {
    return (
        <div>
            <div>
                <div className="about-banner gradient-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>About Qalam</h2>
                                <p data-aos="zoom-in-up">
                                    Founded by Mr. Veer Singh, Jeet Singh Nihal Singh has been a pioneer in the spice industry. His vision and passion for delivering premium quality products have shaped the company's journey, making it a trusted name in households across Punjab and beyond.
                                </p>
                            </div>
                            <div className="col-12 text-center">
                                <div className="table-bg">
                                    <div className="table-content  d-sm-flex">
                                        <div className="table-img-wrapper">

                                            <img src={display} />
                                        </div>
                                        <div className='d-none d-sm-block'>
                                            <ul>
                                                <li>
                                                    <FaCheck /> Iodine for Thyroid Health
                                                </li>
                                                <li><FaCheck /> Essential Minerals</li>
                                                <li>
                                                    <FaCheck /> Flavor Enhancement
                                                </li>
                                                <li>
                                                    <FaCheck /> Hydration Support
                                                </li>
                                                <li>
                                                    <FaCheck /> Overall Well-being
                                                </li>
                                            </ul>
                                            <button id="sparkwork-about-get-started-free" className="btn" onClick={handleCallClick}>
                                                Call Us Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-6  d-sm-block">
                                <div className="about-img-wrapper">
                                    <img src={about1} />
                                    <div className="about2 up-down-animation">
                                        <img src={about2} />
                                    </div>
                                    <div className="about3">
                                        <img src={about3} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                                <div className="about-text">
                                    <span className="about-tag">Know About Us</span>
                                    <h3>Jeet Singh Nihal Singh</h3>
                                    <p>
                                        Founded in 1975 by Mr. Veer Singh, Jeet Singh Nihal Singh is a renowned spice and condiment company based in Muktsar, Punjab. A symbol of dedication, our diverse product range, from Iodized Salt to Chaat Masala Powder, reflects our commitment to superior quality. Our exclusive Qalam 100gm line, featuring Chai Masala Powder and more, showcases innovation. Mr. Veer Singh's vision has made us a trusted name in Punjab and beyond. Rooted in Muktsar, we draw inspiration from the region's culinary traditions. Our products not only highlight Indian spice diversity but also prioritize authenticity, capturing the true essence of each spice.
                                    </p>
                                    <p>
                                        Our platform is built on the latest technologies and best practices
                                        in learning and development to provide employees with a
                                        user-friendly and intuitive platform that fits their learning needs
                                        and preferences. Whether you're a busy employee who needs to learn
                                        on-the-go or a manager who wants to create customized learning paths
                                        for your team, Sparkwork LXP has you covered.
                                    </p>
                                    {/* <div className="about-lms">
                                        <ul>
                                            <li>
                                                <span> <i className="bi bi-pencil-square" /> </span> Easily create
                                                online content
                                            </li>
                                            <li>
                                                <span> <i className="bi bi-gear" /> </span> Customize with 100+
                                                settings &amp; configurations
                                            </li>
                                            <li>
                                                <span> <i className="bi bi-patch-question" /> </span> Add quizzes
                                                for instant assessment
                                            </li>
                                            <li>
                                                <span><i className="bi bi-people" /> </span> online collaboration
                                            </li>
                                            <li>
                                                <span><i className="bi bi-box-seam" /> </span> SCORM integrations
                                            </li>
                                            <li>
                                                <span> <i className="bi bi-clipboard-data" /> </span> View
                                                delightful reports &amp; analytics
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="more-about-text gradient-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="about-p">
                                    <h3>Why Choose Jeet Singh Nihal Singh for Your Salt Needs?

                                    </h3>


                                    <div className="about-lms">
                                        <ul>
                                            <li>
                                                <span> <FaCheck />
                                                </span>  Proven Legacy of Quality: With a foundation laid in 1975 by Mr. Veer Singh, our company boasts decades of commitment to providing superior products. Our Iodized Salt stands as a testament to our legacy of quality.
                                            </li>
                                            <li><span> <FaCheck /> </span> Diverse Range: From essential Iodized Salt to specialty salts like Pink Salt and Black Salt, we offer a comprehensive range to cater to various culinary preferences. Our products cater to diverse palates, ensuring there's a perfect match for every taste.</li>
                                            <li><span> <FaCheck /> </span>Innovation in Packaging: Our exclusive Qalam 100gm range, featuring Chai Masala Powder and others, showcases our commitment to innovation in packaging and delivering unique offerings to our customers.</li>
                                            <li><span> <FaCheck /> </span>Rooted in Tradition: Based in Muktsar, Punjab, we draw inspiration from the rich agricultural heritage and culinary traditions of the region. Our salt products are deeply ingrained in local authenticity.</li>
                                            <li><span> <FaCheck /> </span>Community Contribution: Jeet Singh Nihal Singh is not just a company; it's an integral part of the local community. We contribute to the flavors that define Punjabi cuisine, ensuring our salts reflect the true essence of the region.</li>
                                            <li><span> <FaCheck /> </span>Commitment to Excellence: Our commitment to quality extends beyond tradition. We prioritize authenticity, ensuring that each salt product captures the genuine essence of the salts they represent.</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <img src={about} className="up-down-animation" />
                            </div>
                        </div>
                    </div>
                </div>
                
        
                </div>


        </div >

    )
}

export default About