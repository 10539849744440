import React, { useEffect, useState } from 'react'
import './Header.scss'
import { Link } from 'react-router-dom'
import Logo from '../assets/images/logo.png'
import { RxHamburgerMenu } from "react-icons/rx";

const Header = () => {
    const [scrolling, setScrolling] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            // Check if the user has scrolled down (you can customize this logic)
            if (window.scrollY > 50) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolling]);

    // Add a CSS class conditionally based on the scrolling state


    return (
        <nav className={scrolling ? 'navbar navbar-expand-lg fixed-top nav_bg' : 'navbar navbar-expand-lg fixed-top'}>
            <div className="container">
                <Link className="navbar-brand" href="/"><img src={Logo} />  <div className='qalam-text logo-mobile'>Qalam में ताकत है</div> </Link>
                <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><RxHamburgerMenu /></span>
                </button>
                
                <div className="collapse navbar-collapse  justify-content-end" id="navbarScroll">
                   
                    <ul className="navbar-nav me-auto my-3 my-lg-0 navbar-nav-scroll  justify-content-end w-100">
                        <div className='qalam-text logo-desktop'>Qalam में ताकत है</div>
                        <li className="nav-item">
                            <Link to={'/'} className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/about'} className="nav-link " >About</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/products'} className="nav-link " >Our Products</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/contact'} className="nav-link " >Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}

export default Header