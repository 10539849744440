import './App.css';
import Header from './Header/Header';
import Home from './Home/Home';
import {Routes, Route } from 'react-router-dom';
import './App.css'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import Footer from './Footer/Footer';
import About from './About/About';
import Gallery from './Gallery/Gallery';
import Contact from './Contact/Contact';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500
    });
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
       
    </div>
  );
}

export default App;
