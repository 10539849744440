import React, { useEffect } from 'react'
import './Banner.scss'


import { SiGooglemybusiness } from "react-icons/si";
import { FaCalendarAlt } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
import { handleCallClick } from '../reusable/CallUs'

const Banner = () => {

   
    return (
        <div>
            <div className="banner-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="banner-content">
                                <div className="banner-title" data-aos="fade-right">
                                    Welcome to Qalam Family
                                    <h3> Contributing to the flavors that define Punjabi cuisine.</h3>
                                </div>
                                <div className="banner-description" data-aos="zoom-out">
                                    Our product range reflects not just the diversity of Indian spices but also our commitment to excellence. We prioritize authenticity, ensuring that each product captures the true essence of the spices it represents.
                                </div>
                                <button className="btn btn-lg" id="sparkwork-get-started-free-banner" onClick={handleCallClick}>CALL US NOW</button>
                                <div className="banner-icons-wrapper">
                                    <div className="icon-box">
                                        <SiGooglemybusiness data-aos="zoom-in" />
                                        <strong>Nature of Business </strong>
                                        <span>Manufacturer & Supplier</span>
                                    </div>
                                    <div className="icon-box">
                                        <FaCalendarAlt data-aos="zoom-in" />
                                        <strong>Year of Establishment</strong>
                                        <span>1975</span>
                                    </div>
                                    <div className="icon-box">
                                        <FaMapMarkedAlt data-aos="zoom-in" />
                                        <strong>Market Covered</strong>
                                        <span>Pan India</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center d-none d-sm-block">
                            {/* <img src={Logo} className="up-down-animation banner-side-img" /> */}
                        </div>
                    </div>
                </div>
            </div>

        </div >

    )
}

export default Banner