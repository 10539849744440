import React from 'react'
import './Contact.scss'
import contactImg from '../assets/images/contact_illustration.png'
import shape from '../assets/images/contact_bg.png'
const Contact = () => {
    return (
        <div>
            <div>
                <div className="gradient-bg map-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 text-center">
                                <h3>CONTACT US</h3>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3438.8057195789156!2d74.50435757606763!3d30.46994049828473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39176fc37a378bf9%3A0xf952c45bde9fa40c!2sQalam%20salt!5e0!3m2!1sen!2sin!4v1708754668791!5m2!1sen!2sin" width="100%" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className="contact-wrapper">
                <img src={shape} class="d-none d-sm-block" />
                <div className="contact-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 col-sm-12">
                                <img src={contactImg} />
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <div className="contact-text">
                                    <h2>Have Some Questions ?</h2>
                                    <p>
                                        If you have questions about our premium salt products, need assistance with an order, or want to explore partnership opportunities, we're here to help. Email us at arungumber663@gmail.com and our dedicated team will get back to you promptly. Thank you for choosing our quality salts!
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>


    )
}

export default Contact