import React from 'react'
import './Gallery.scss'
import bgShape from '../assets/images/gallery/bg-shape.svg';
import laptop from '../assets/images/gallery/laptop-hero.png';
import character from '../assets/images/gallery/character.png';
import { handleCallClick } from '../reusable/CallUs';
import bannerImg from '../assets/images/gallery/banner.png'
import Services from '../Services/Services';
const Gallery = () => {
    return (
        <div>
            <div className="container-fluid solution-page-wrapper gradient-bg">
                <div className="banner-wrapper-img">
                    <img src={bgShape} className="bg-shape d-none d-md-block" />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5" data-aos="zoom-in-up">
                                <div className="solution-banner-text">
                                    <h2>Pure Essence Iodized Salt by Jeet Singh Nihal Singh</h2>
                                    <p>
                                        Discover the unparalleled purity and health benefits with our Pure Essence Iodized Salt. Meticulously crafted by Jeet Singh Nihal Singh, it not only enhances the flavors of your dishes but also supports thyroid health. Elevate your culinary experience with the goodness of our premium salt.
                                    </p>
                                    <button className="generic-button" id="sparkwork-solution-get-started" onClick={handleCallClick}> CALL US NOW</button>
                                </div>
                            </div>
                            <div className="col-md-7" data-aos="zoom-in-up">
                                <div className="solution-banner-image">
                                    <img className="up-down-animation" src={laptop} alt />
                                    <div className="user-img d-none d-md-block">
                                        <img src={character} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution-title" data-aos="zoom-in-up">
                    Exquisite Culinary Delights by Jeet Singh Nihal Singh
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8" data-aos="zoom-in-up">
                            <div className="solution-section-list">
                                Welcome to a world where tradition meets innovation – Jeet Singh Nihal Singh's exceptional range of spices and salts. Established in 1975 by the visionary Mr. Veer Singh, our company stands as a testament to decades of unwavering dedication to providing superior culinary products.
                                <br /><br />
                                Our diverse product line encompasses kitchen essentials like Iodized Salt, vibrant Chaat Masala Powder, aromatic Spices Powder, Sendha Namak Powder, and unique varieties such as Pink Salt and Black Salt. We take pride in introducing our exclusive Qalam 100gm range, featuring Chai Masala Powder, Multani Mitti Powder, Lassi Masala Powder, and Paratha Masala Powder – a testament to our commitment to innovation and meeting diverse culinary preferences.
                                <br /><br />
                                At Jeet Singh Nihal Singh, we draw inspiration from the rich agricultural heritage and culinary traditions of Muktsar, Punjab, our home base. This connection to our roots ensures that each product captures the authentic essence of the spices and salts it represents.
                                <br /><br />
                                Our legacy is built on a foundation of quality and authenticity. Mr. Veer Singh's vision has made us a trusted name in households across Punjab and beyond. We don't just offer products; we offer an experience – a journey through the flavors and traditions that define Punjabi cuisine. Choose Jeet Singh Nihal Singh for an unparalleled culinary adventure where every spice and salt tells a story of excellence and heritage.
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in-up">
                            <div className="solution-list-img float-end" data-aos="zoom-in-up">
                                <img src={bannerImg} className="section-laptop-img up-down-animation"  />
                                {/* <span className="list-img-popup-1 d-none d-md-flex up-down-animation">
                                    <img src="../../assets/images/solution-icons/green-tick.svg" />
                                    Systems integration
                                </span>
                                <span className="list-img-popup-1 d-none d-md-flex popup-2 up-down-animation">
                                    <img src="../../assets/images/solution-icons/green-tick.svg" />
                                    Metrics for tracking knowledge utilization
                                </span> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                <Services />
                </div>

            </div>
        </div>

    )
}

export default Gallery