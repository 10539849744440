import React from 'react'
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import AwesomeSlider from 'react-awesome-slider';

import 'react-awesome-slider/dist/styles.css';
import './Slider.scss'
import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
import slide1 from '../assets/images/slides/1.jpg'
import slide2 from '../assets/images/slides/2.jpg'
import slide3 from '../assets/images/slides/3.jpg'
import slide4 from '../assets/images/slides/4.jpg'
const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => {
    const data = [
        {
            id: "1",
            icon: "asset/mobile.png",
            title: "Qalam Pink Salt",
            desc: "Pink salt, such as Himalayan salt, contains trace minerals that can contribute to overall mineral balance in the body.",
            img: slide1
        },
        {
            id: "2",
            icon: "asset/globe.png",
            title: "Qalam Black Salt",
            desc:
                "Black salt may help improve digestion and alleviate bloating.",
            img: slide2
        },
        {
            id: "3",
            icon: "asset/writing.png",
            title: "Qalam Cooking Salt",
            desc: "Cooking salt enhances and elevates the flavor of food.",
            img: slide3
        }
        ,
        {
            id: "4",
            icon: "asset/writing.png",
            title: "Qalam Iodised Salt",
            desc: "Iodized salt helps prevent iodine deficiency, supporting proper thyroid function and preventing related health issues.",
            img: slide4
        }
    ];
    return (
        <div className='slider-wrapper'>
            <AutoplaySlider 
            play={true} 
            interval={1000}
                animation="fallAnimation">
                {data.map((d) => (
                    <div className="item">
                        <div className="left">
                            <div className="leftContainer">
                                <div className="imgContainer">
                                    <img src={d.img} />
                                </div>
                                <div className='image-desc'>


                                    <h2>{d.title}</h2>
                                    <p>{d.desc} </p></div>
                            </div>
                        </div>

                        {/* <div className="right">
                            <img src="https://99designs-blog.imgix.net/blog/wp-content/uploads/2018/10/attachment_100040756-e1538485934255.jpeg?auto=format&q=60&fit=max&w=930" />
                        </div> */}
                    </div>
                ))}
            </AutoplaySlider>
        </div>
    )
}

export default Slider